<template>
  <div v-if="data">
    <b-modal
      id="historyDataModal"
      ref="historyDataModal"
      title="Soru Yanıtları"
      ok-only
      size="xl"
      ok-title="Ok"
    >
      <user-training-history-questions
        v-if="selectedHistory"
        :data="selectedHistory.question_data"
        :title="'Soruya verilen yanıtlar'"
      />
    </b-modal>
    <b-row>
      <b-col md="12">
        <b-card no-body>
          <b-card-header>
            <!-- title and subtitle -->
            <div>
              <b-card-title class="mb-1">
                {{ title }}
              </b-card-title>
              <b-card-sub-title>Satır üzerine çift tıklayarak sorulara verilen cevapları görebilirsiniz.</b-card-sub-title>
            </div>
            <!--/ title and subtitle -->

            <!-- badge -->
            <div class="d-flex align-items-center flex-wrap mt-sm-0 mt-1" />
            <!--/ badge -->
          </b-card-header>

          <b-card-body>
            <b-table
              small
              :fields="historyTable.fields"
              :items="data"
              selectable
              responsive="sm"
              select-mode="single"
              @row-selected="historySelect"
            >

              <!-- A custom formatted column -->
              <template #cell(time)="data">
                {{ data.value+' DK.' }}
              </template>
              <!-- A custom formatted column -->
              <template #cell(question_count)="data">
                {{ data.item.complete_stats.question_count }}
              </template>
              <template #cell(answer_count)="data">
                {{ data.item.complete_stats.answer_count }}
              </template>
              <template #cell(total_point)="data">
                {{ data.item.complete_stats.total_point }}
              </template>
              <template #cell(user_point)="data">
                {{ data.item.complete_stats.user_point }}
              </template>
              <template #cell(complete_rate)="data">

                <b-progress

                  max="100"
                  variant="warning"
                  striped
                >
                  <b-progress-bar :value="data.item.complete_stats.complete_rate">
                    <strong>{{ data.item.complete_stats.complete_rate }} %</strong>
                  </b-progress-bar>

                </b-progress>
              </template>
              <template #cell(is_complete)="data">
                <b-badge
                  v-if="data.value==1"
                  pill
                  :variant="data.item.complete_stats.is_success?'success':'warning'"
                >
                  {{ data.item.complete_stats.is_success?'Başarılı Tamamlandı':'Başarısız Tamamlandı' }}
                </b-badge>
                <b-badge
                  v-else
                  pill
                  variant="danger"
                >
                  Tamamlanmadı
                </b-badge>
              </template>
              <!-- Optional default data cell scoped slot -->
              <template #cell()="data">
                {{ data.value }}
              </template>
            </b-table>
          </b-card-body>
        </b-card>
      </b-col>

    </b-row>

  </div>
</template>

<script>
import {
  BCard, BCardBody, BCardHeader, BCardTitle, BCardSubTitle, BTable, BRow, BCol, BModal, VBModal, BBadge, BProgress, BProgressBar,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import userTrainingHistoryQuestions from './UserTrainingHistoryQuestions.vue'

export default {
  components: {
    BCardHeader,
    BCard,
    userTrainingHistoryQuestions,
    BCardBody,
    BCardTitle,
    BProgress,
    BProgressBar,
    BBadge,
    BCardSubTitle,
    BModal,
    BTable,
    BRow,
    BCol,
  },
  directives: {
    'b-modal': VBModal,
    Ripple,
  },
  props: {
    data: {
      type: Array,
      default: () => [],
    },
    title: {
      type: String,
      default: '',
    },
    subtitle: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      historyTable: {
        fields: [
          { key: 'question_count', label: 'Toplam Soru' },
          // A regular column
          { key: 'answer_count', label: 'Verilen Cevap Sayısı' },
          { key: 'total_point', label: 'Gerekli Toplam Puan' },
          // A regular column
          { key: 'user_point', label: 'Kazanılan Puan' },
          { key: 'complete_rate', label: 'Başarı Oranı' },
          // A virtual column made up from two fields
          { key: 'is_complete', label: 'Tamamlanma Durumu' },
          { key: 'created_at', label: 'Sınava Başlama Zamanı' },
        ],
      },
      selectedHistory: null,
    }
  },
  created() {
  },
  methods: {
    // eslint-disable-next-line no-unused-vars
    historySelect(item) {
      // eslint-disable-next-line prefer-destructuring
      this.selectedHistory = item[0]
      this.$refs.historyDataModal.show()
    },
  },
}
</script>
